// 列表页上方操作栏，筛选条件、新增导入等按钮 的盒子

<template>
  <div class="operation">
    <div class="search-box">
      <slot name="search-box"></slot>
    </div>

    <div class="btn-box">
      <slot name="btn-box"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.operation {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;

  .search-box {
    /deep/ .el-form {
      .el-form-item {
        margin-bottom: 10px;
      }
    }
  }

  .btn-box {
    max-width: 400px;
    padding-top: 2px;

    .el-button {
      margin: 0 10px 5px 0;
    }
  }
}
</style>
